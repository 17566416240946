/**
 * Created by LENOVO-T430 on 1/20/2017.
 */
angular.module('app').constant('USER_ROLES', {
	all: '*',
	admin: 'admin',
	organizer: 'organizer',
	moderator: 'moderator',
	editor: 'editor',
	participant: 'participant',
	driver: 'driver'
});